<template>
  <div class="page-wrapper">
    <van-tabs v-model="active" color="#3C37D2">
      <van-tab title="基本信息">
        <van-cell-group inset style="margin-top: 0.2rem" v-if="info">
          <van-cell title="业务流水号" :label="info.acceptNo" />
          <van-cell title="办事项" :label="info.itemName" />
          <van-cell title="办事人" :label="info.contactUsr" />
          <van-cell
            title="办事人联系方式"
            :label="JSON.parse(info.formValue).phone || info.contactPhe"
          />
          <van-cell title="进度" :label="info.statusName" />
        </van-cell-group>
      </van-tab>
      <van-tab title="受理信息">
        <div style="margin-top: 0.2rem" v-if="info">
          <van-steps direction="vertical" :active="progress.length">
            <van-step>
              <p>{{ info.createTime }}</p>
              <p>申请</p>
            </van-step>
            <van-step v-for="(item, index) in progress" :key="index">
              <p>{{ item.endTime }}</p>
              <p>{{ item.name }}</p>
              <p>处理意见：{{ item.comment || "-" }}</p>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { applyInfoDetail, itemProcessProgress } from "@/api/policy/apply";
export default {
  data() {
    return {
      active: 0,
      id: "",
      info: null,
      progress: [],
    };
  },
  created() {
    this.initId();
    this.getInfo();
    this.getProgress();
  },
  mounted() {},
  methods: {
    initId() {
      const { id } = this.$route.params;
      if (id) {
        this.id = id;
      }
    },
    getInfo() {
      const params = {
        bizCode: this.id,
      };
      applyInfoDetail(params).then((res) => {
        this.info = res.data;
      });
    },
    getProgress() {
      const params = {
        bizCode: this.id,
      };
      itemProcessProgress(params).then((res) => {
        this.progress = res.data.his || [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>